import React, { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Tabs,
  Button,
  DatePicker,
  Space,
  version,
  Modal,
  AutoComplete,
  Pagination,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  Badge,
  Row,
  Select,
} from "antd";
import "antd/dist/antd.css";
import LiveDealsTableView from "./live_deals_view";
import DeletedDealsTableView from "./deleted_deals_view";
import RequestedDealsTableView from "./requested_deals";
import UsersTableView from "./users_view";
import MailCountTableView from "./mail_open_count";

import { useKeycloak } from "@react-keycloak/web";
import { createContext } from "react";
import * as serviceCaller from "../../Services";

export const context = createContext();
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function DealsView(props) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [keycloak, initialized] = useKeycloak();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [livedealsData, setLiveDealsData] = React.useState();
  const [UsersData, setUsersData] = React.useState([]);
  const [UserPage, setUserPage] = React.useState(1);
  const [UserPageSize, setUserPageSize] = React.useState(10);
  const [deleteddealsData, setDeletedDealsData] = React.useState();
  const [requestedDealsData, setRequestedDealsData] = React.useState();
  const [Tab, setTab] = React.useState();
  const [refreshDeals, setRefreshDeals] = React.useState(false);
  const [RefreshUsersData, setRefreshUsersData] = React.useState(false);
  const [refreshDeletedDeals, setRefreshDeletedDeals] = React.useState(false);

  useEffect(() => {
    getUsersDatas();
  }, [props?.refresh, RefreshUsersData, Tab, props?.searchuser]);

  useEffect(() => {
    getDealsDatas();
    getDeletedDealsDatas();
    getRequestedDealsDatas();
  }, [props?.refresh, refreshDeals, Tab]);

  useEffect(() => {
    getDealsDatas();
  }, [props.searchdeal]);

  const getDealsDatas = async (
    filter = {},
    page = 1,
    page_size = 10,
    deal_type = "all",
    sort = "created_on",
    search
  ) => {
    const LiveDeals = await serviceCaller.getDeals(
      filter,
      page,
      page_size,
      deal_type,
      sort,
      (search = props.searchdeal)
    );
    setLiveDealsData(LiveDeals?.data);
  };

  const getUsersDatas = async (
    filter = {},
    page = 1,
    page_size = 10,
    deal_type = "all",
    sort = "created_on",
    search
  ) => {
    const Users = await serviceCaller.getUsers(
      filter,
      page,
      page_size,
      deal_type,
      sort,
      (search = props?.searchuser)
    );
    console.log("Users : ", Users);
    setUsersData(Users?.data);
  };

  const getDeletedDealsDatas = async (
    filter = {},
    page = 1,
    page_size = 10,
    deal_type = "all",
    sort = ["created_on"]
  ) => {
    const DeletedDeals = await serviceCaller.getDeletedDeals(
      filter,
      page,
      page_size,
      deal_type,
      sort
    );
    setDeletedDealsData(DeletedDeals?.data);
  };
  const getRequestedDealsDatas = async (
    filter = {},
    page = 1,
    page_size = 10,
    deal_type = "all",
    sort = ["created_on"]
  ) => {
    const requestedDeals = await serviceCaller.getRequestedDeals(
      filter,
      page,
      page_size,
      deal_type,
      sort
    );
    setRequestedDealsData(requestedDeals?.data);
  };

  // console.log("livedealsData",livedealsData);
  console.log("UsersData",UsersData)
  console.log("dealsboard-index-props", props);
  // console.log("deleteddealsData",deleteddealsData)
  return (
    <>
      <div>
        <div className="card-container">
          <Tabs
            defaultActiveKey="1"
            type="card"
            onChange={(e) => {
              // console.log("eee",e)
              setTab(e);
              props.Tab(e);
            }}
          >
            <Tabs.TabPane
              tab={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>{"live Deals"}</span> &nbsp;&nbsp;
                  <Badge
                    count={livedealsData?.totalDocs}
                    overflowCount={99999}
                    style={{ fontSize: "10px" }}
                  />
                </div>
              }
              key="1"
            >
              <LiveDealsTableView
                data={livedealsData?.docs}
                deals={"live"}
                Tab={Tab}
                reloadDeals={refreshDeals}
                AllData={livedealsData}
                PaginationUpdate={(page = 1, pagesize = 10) => {
                  getDealsDatas({}, page, pagesize, "", []);
                }}
                OnEdit={(value) => {
                  props?.OnEdit(value);
                }}
                Refresh={() => {
                  setRefreshDeals(!refreshDeals);
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>{"Deleted Deals"}</span> &nbsp;&nbsp;
                  <Badge
                    count={deleteddealsData?.totalDocs}
                    overflowCount={99999}
                    style={{ fontSize: "10px" }}
                  />
                </div>
              }
              key="2"
            >
              <DeletedDealsTableView
                data={deleteddealsData?.docs}
                AllData={deleteddealsData}
                reloadDeals={refreshDeals}
                Tab={Tab}
                deals={"deleted"}
                PaginationUpdate={(page = 1, pagesize = 10) => {
                  getDeletedDealsDatas({}, page, pagesize, "", []);
                }}
                Refresh={() => {
                  setRefreshDeals(!refreshDeals);
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>{"Requested Deals"}</span> &nbsp;&nbsp;
                  <Badge
                    count={requestedDealsData?.totalDocs}
                    overflowCount={99999}
                    style={{ fontSize: "10px" }}
                  />
                </div>
              }
              key="3"
            >
              <RequestedDealsTableView
                data={requestedDealsData?.docs}
                AllData={requestedDealsData}
                reloadDeals={refreshDeals}
                Tab={Tab}
                deals={"deleted"}
                PaginationUpdate={(page = 1, pagesize = 10) => {
                  getRequestedDealsDatas({}, page, pagesize, "", []);
                }}
                Refresh={() => {
                  setRefreshDeals(!refreshDeals);
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>{"Users"}</span> &nbsp;&nbsp;
                  <Badge
                    count={UsersData?.totalDocs}
                    overflowCount={99999}
                    style={{ fontSize: "10px" }}
                  />
                </div>
              }
              key="4"
            >
              <div
                style={{
                  height: "76vh",
                  overflowY: "scroll",
                  paddingTop: "2%",
                  paddingBottom: "2%",
                }}
              >
                <UsersTableView
                  data={UsersData?.docs}
                  AllData={UsersData}
                  reloadDeals={refreshDeals}
                  Tab={Tab}
                  PaginationUpdate={(page = 1, pagesize = 10) => {
                    getUsersDatas({}, page, pagesize, "", []);
                  }}
                  Refresh={() => {
                    setRefreshUsersData(!RefreshUsersData);
                  }}
                />
                {/* <div style={{textAlign:"center",paddingTop:"1%"}}>
                <Pagination
                  size="default"
                  defaultCurrent={1}
                  defaultPageSize={10}
                  total={UsersData?.totalDocs}
                  responsive={true}
                  onChange={(value, pageSize) => {
                    console.log("setUserPage-value",value);
                    console.log("setUserPagesize-pagesize",pageSize)
                    setUserPage(value);
                    setUserPageSize(pageSize)
                  }}
                />
              </div> */}
              </div>
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab="Mail Open Count" key="4">
              <MailCountTableView data={[]}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Report" key="5">
              <MailCountTableView data={[]}/>
            </Tabs.TabPane> */}
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default DealsView;
