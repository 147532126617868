import moment from "moment";
import {
  Avatar,
  Switch,
  Pagination,
  Empty,
  Button,
  Table,
  Modal,
  notification,
  Form,
  Input
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FaAmazon } from 'react-icons/fa';
import { RiAmazonFill } from 'react-icons/ri';
import { MdDelete, MdOutlineStorefront } from 'react-icons/md';
import { VscDebugRestart } from 'react-icons/vsc';
import styled from 'styled-components';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as serviceCaller from "../../Services";
import MuiAlert from '@mui/material/Alert';
const Wrapper = styled.div`
  .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
    /* padding: 8px 8px; */
    padding: 5px;
  }

`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const RequestedDealsViewTable = (props) => {
  const [form] = Form.useForm();
  const [dataArray, setDataArray] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [pageSizeValue, setPageSizeValue] = React.useState();
  const [asinModal, setAsinModal] = React.useState(); 
  const [confirmLoading, setConfirmLoading] = useState(false);

  // console.log("request-props",props)

  useEffect(() => {
    if (window?.screen?.height > 900) {
      setPageSizeValue(15);
    } else {
      setPageSizeValue(10);
    }
  }, []);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setArrayDataforDeals();
    } else {
      setDataArray();
    }
  }, [props?.Tab, props?.refreshDeals, props?.data?.length, props]);

  const setArrayDataforDeals = async () => {
    const dataData = [];
    await props?.data?.map((item, index) => {
      item.key = index;
      dataData?.push(item);
    })
    setDataArray(dataData);
  }


  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const columns = [
    {
      title: "Customer",
      width: 300,
      fixed: "left",
      render: (value) => {
        return (
          <>
            <div>{value?.Users[0]?.email}</div>
          </>
        )
      },
    },
    {
      title: "Category Name",
      key: 'c_name',
      width: 150,
      fixed: "left",
      render: (value) => {
        return (
          <>
            <div>{value?.cat_lists[0]?.name}</div>
          </>
        )
      },
    },
    { title: "ROI", width: 150, dataIndex: 'roi', key: 'roi', fixed: "left" },
    { title: "Net Profit", width: 150, dataIndex: 'netprofit', key: 'netprofit' },
    { title: "BSR", width: 150, dataIndex: 'bsr', key: 'bsr' },
    { title: "Shared People", width: 150, dataIndex: 'sharedpeople', key: 'sharedpeople' },
    {
      title: "hazmat",
      width: 150,
      render: (value) => {
        return (
          <>
            <div>
              {value?.hazmat ? "YES" : "NO"}
            </div>
          </>
        )
      },
    },
    { title: "Est Monthly sales", width: 150, dataIndex: 'estmonthlysales', key: 'estmonthlysales' },
    {
      title: "Added On",
      width: 150,
      key: 'updated_on',
      render: (value) => {
        return (
          <>
            <div>
              {`${moment(value?.created_on).format("DD-MMM-YYYY")}`}
            </div>
          </>
        )
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>ADD Deals</div>,
      width: 100,
      key: 'action',
      fixed: "right",
      render: (value) => {
        return (
          <div style={{ textAlign: "center" }}>
            {value?.status === "submitted" ? (
              <Button
                type="primary"
                size="small"
                style={{ fontSize: "12px",backgroundColor:"green" }}
              >
                Submitted
              </Button>
            ) : (
              <Button
                type="primary"
                size="small"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  console.log("value?.Users[0] : ", value?.Users[0]);
                  setAsinModal(value);
                }}
              >
                Add ASIN
              </Button>
            )}
          </div>
        )
      },
    },
  ];

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Wrapper>
      {dataArray?.length > 0 ? (
        <div style={{ marginLeft: "5px", marginRight: "5px" }}>
          <Table
            size='small'
            columns={columns}
            dataSource={dataArray}
            pagination={false}
            style={{ height: "80vh", overflow: "auto" }}
            scroll={{ x: 1500 }}
          />
          <Pagination
            style={{ float: "right" }}
            size='small'
            defaultCurrent={1}
            defaultPageSize={pageSizeValue}
            total={props?.AllData?.totalDocs}
            responsive={true}
            onChange={(value, pageSize) => {
              props?.PaginationUpdate(value, pageSize);
            }}
          />
        </div>
      ) : (
        <div>
          <div style={{ margin: "10%" }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      )}
      <div>
        <Modal
          title={`ADD ASIN`}
          open={asinModal}
          confirmLoading={confirmLoading}
          onCancel={() => {
            form?.resetFields();
            setAsinModal(undefined);
          }}
          footer={null}
        >
          <Form
            form={form}
            name="asinForm"
            initialValues={{
              remember: true,
            }}
            onFinish={async (value) => {
              setConfirmLoading(true);
              console.log("value : ", value)
              const requestedDeals = await serviceCaller.AddASINtoUser(asinModal, value?.asin);
              console.log("requestedDeals : ", requestedDeals);
              if (requestedDeals?.data?.error) {
                setConfirmLoading(false);
                notification.error({
                  message: requestedDeals?.data?.message,
                });
              } else {
                form?.resetFields();
                setAsinModal(undefined);
                setConfirmLoading(false);
                notification.success({
                  message: requestedDeals?.data?.message,
                });
              }
            }}
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item
              label="USER"
            >
              <div style={{ textAlign: "right" }}>
                <span>{asinModal?.Users[0]?.email}</span>
              </div>
            </Form.Item>
            <Form.Item
              label="ASIN"
              name="asin"
              rules={[
                {
                  required: true,
                  message: 'Please enter the asin!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <div>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Note archived"
          action={action}
        >

          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Deals successfully updated
          </Alert>
        </Snackbar>
      </div>
    </Wrapper>
  )
}
export default RequestedDealsViewTable;