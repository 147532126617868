import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Grid,
  Tabs,
  Tab,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Col,
  Tooltip,
  Button,
  Row,
  Avatar,
  Modal,
  Form,
  Input,
  Radio,
  Switch,
  Select,
  InputNumber ,
  notification,
} from "antd";
import { PlusCircleOutlined, UserAddOutlined } from "@ant-design/icons";
import XLSX from "xlsx";
import { useSnackbar } from "notistack";
import * as Httprequest from "../component/http_request";
import DealsListIcon from "../assets/deal.png";
import * as serviceCaller from "../Services";
import PlanListIcon from "../assets/price-list.png";
// import PlanView from "./Plan_board";
import DealsView from "./Deals_board";
import { CgChevronDoubleLeft } from "react-icons/cg";
import * as StripeUser from "../Services/plans";
import styled from "styled-components";
import { UploadOutlined } from "@ant-design/icons";
import { message, Upload , Space } from "antd";
const Wrapper = styled.div`
  .ant-modal-body {
    padding-left: 24px;
    font-size: 14px;
    padding-right: 24px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  .ant-table-content {
    overflow: inherit !important;
    height: 76vh !important;
  }
`;

const style = {
  background: "#0092ff",
  padding: "8px 0",
};
const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
const DashboardIndex = (props) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [bulkUploads, setBulkUploads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dashBoard, setDashBoard] = useState("deals");
  const [dealsData, setDealsData] = React.useState();
  const [addPlan, setAddPlan] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [Category, setCategory] = useState([]);
  const [EditData, setEditData] = useState(false);
  const [ButtonLoading, setButtonLoading] = useState(false);
  const [tabvalue,setTabvalue] = useState("1");
  const [searchdeal,setSearchdeal] = useState("");
  const [searchuser,setSearchuser] = useState("");

  const { Search } = Input;
  const onSearch = (value) => console.log(value);

  useEffect(() => {
    getDealsDatas();
    getCategoryDatas();
  }, []);

  useEffect(() => {
    console.log("props : ", props);
  }, [props]);

  

  const getDealsDatas = async () => {
    const res = await serviceCaller.getDeals();
    setDealsData(res?.data?.docs);
  };

  const getCategoryDatas = async () => {
    const res = await serviceCaller.getCategory();
    setCategory(res?.data?.docs);
  };

  // bulk deal upload
  const bulkDealUpload = (e) => {
    let files = e.target.files;
    if (files && files[0]) setBulkUploads({ file: files[0] });
  };

  // bulk deals upload
  const bulkDeals = async () => {
    try {
      setLoading(true);
      // convert file to json
      const reader = new FileReader();

      const rABS = !!reader.readAsBinaryString;
      reader.onload = async (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          bookVBA: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const dealList = XLSX.utils.sheet_to_json(ws);
        let datafilters = dealList.filter(
          (edit) => edit.plan_type == "Silver" || edit.plan_type == "silver"
        );
        let datafiltersz = dealList.filter(
          (edit) => edit.plan_type == "Gold" || edit.plan_type == "gold"
        );
        let res = await serviceCaller.uploadDeal(dealList);
        if (res.status == 200) {
          setLoading(false);
          enqueueSnackbar(res.data.message, {
            variant: "success",
          });
          window.location.reload();
        } else {
          setLoading(false);
          enqueueSnackbar(res.data.message, {
            variant: "error",
          });
        }
      };

      if (rABS) {
        reader.readAsBinaryString(bulkUploads.file);
      } else {
        reader.readAsArrayBuffer(bulkUploads.file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function toggleModal() {
    setIsOpen(!isOpen);
    console.log(isOpen, "isOpen");
  }

  const submitSingleDeal = async (values) => {
    console.log("valuesservicecaller", values);
    let response = await serviceCaller.addDeal(values);
    if (response.status == 200) {
      setLoading(false);
      enqueueSnackbar(response.data.message, {
        variant: "success",
      });
      window.location.reload();
    } else {
      setLoading(false);
      enqueueSnackbar(response.data.message, {
        variant: "error",
      });
    }
    console.log("Finish:", values);
    form.resetFields();
  };

  console.log("searchuser",searchuser)
  // console.log("searchdeal",searchdeal)
  return (
    <Wrapper>
      <div style={{ width: "99%" }}>
        <div style={{ margin: "10px" }}>
          <Row gutter={24}>
            <Col className="gutter-row" xs={6} sm={6} md={6} lg={6} xxl={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                  ADMIN DASHBOARD
                </div>
                {dashBoard !== "deals" && (
                  <div>
                    <Button
                      type="link"
                      onClick={() => {
                        setAddPlan(true);
                      }}
                    >
                      <PlusCircleOutlined />
                    </Button>
                    <Button
                      type="link"
                      onClick={() => {
                        setAddUser(true);
                      }}
                    >
                      <UserAddOutlined />
                    </Button>
                  </div>
                )}
              </div>
            </Col>
            <Col className="gutter-row" xs={6} sm={6} md={6} lg={6} xxl={6}>
              {dashBoard === "deals" && (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <TextField
                    type="file"
                    onChange={(e) => bulkDealUpload(e)}
                    inputProps={{ accept: ".xls,.xlsx" }}
                  />
                  <Button
                    loading={loading}
                    size="small"
                    onClick={() => bulkDeals()}
                  >
                    Upload
                  </Button>
                </div>
              )}
            </Col>
            <Col className="gutter-row" xs={6} sm={6} md={6} lg={6} xxl={6}>
              <Button variant="contained" color="primary" onClick={toggleModal}>
                ADD NEW DEAL
              </Button>
            </Col>
                <Col className="gutter-row"  xs={6} sm={6} md={6} lg={6} xxl={6}>
              {tabvalue =="1" &&
                <div>
     <Input placeholder="search deal" onChange={(e)=>{
      setSearchdeal(e.target.value);
     }}    style={{
        width: 200,
      }}/>
                </div>}
              
               {tabvalue =="4" && <div>
               <Input placeholder="search user"  
               onChange={(e)=>{
                setSearchuser(e.target.value)
               }}
               style={{
        width: 200,
      }}/>
    
                </div>}
               
                </Col>
              
         
            {/* <Col>
              <Search
                placeholder="input search text"
                allowClear
                onSearch={onSearch}
                style={{
                  width: 200,
                }}
              />
            </Col> */}

            {/* <Col className="gutter-row" xs={1} sm={1} md={1} lg={1} xxl={1}>
              <Tooltip placement="topLeft" title={"Deals list"}>
                <Button
                  type="link"
                  onClick={() => {
                    setDashBoard("deals");
                  }}
                >
                  <Avatar src={DealsListIcon} />
                </Button>
              </Tooltip>
            </Col> */}
            {/* <Col className="gutter-row" xs={1} sm={1} md={1} lg={1} xxl={1}>
              <Tooltip placement="topLeft" title={"Plan list"}>
                <Button
                  type="link"
                  onClick={() => {
                    setDashBoard("plans");
                  }}
                >
                  <Avatar src={PlanListIcon} />
                </Button>
              </Tooltip>
            </Col> */}
            {/* <Col className="gutter-row" xs={1} sm={1} md={1} lg={1} xxl={1} /> */}
          </Row>
        </div>
        <div>
          {dashBoard === "deals" ? (
            <>
              <DealsView
                view={dashBoard}
                refresh={refresh}
                Tab={(value) => {
                  console.log("tabvalue",value)
                  setTabvalue(value);
                }}
                OnEdit={(value) => {
                  console.log("Edit value : ", value);
                  if (value) {
                    setEditData(value);
                    setIsOpen(true);
                    form.setFieldsValue(value);
                  } else {
                    setEditData(undefined);
                  }
                }}
                searchuser={searchuser}
                searchdeal = {searchdeal}
               
              />
            </>
          ) : (
            <>
              {/* <PlanView
                view={dashBoard}
                AddPlan={addPlan}
                onClose={() => {
                  setAddPlan(!addPlan);
                }}
              /> */}
            </>
          )}
        </div>
      </div>
      <Modal
        title="Add User from Stripe"
        open={addUser}
        onClose={() => setAddUser(false)}
        okText="Create"
        cancelText="Cancel"
        onCancel={() => setAddUser(false)}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              const planDatas = await StripeUser.addStripeUser(values);
              if (planDatas?.status === 200) {
                setAddUser(false);
                form?.resetFields();
                notification.success({
                  message: planDatas?.data?.message,
                });
              } else {
                notification.error({
                  message: planDatas?.data?.message,
                });
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <div>
          <Form
            form={form}
            name="register"
            onFinish={() => {}}
            requiredMark={false}
            initialValues={
              {
                // residence: ['zhejiang', 'hangzhou', 'xihu'],
                // prefix: '86',
              }
            }
            scrollToFirstError
          >
            <Row gutter={24} align="middle">
              <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                <Form.Item label="Session Id" />
              </Col>
              <Col xs={12} sm={12} md={18} lg={18} xxl={18}>
                <Form.Item
                  name="subscription_id"
                  rules={[
                    {
                      required: true,
                      message: "Please input your session id",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>

      {/* single deal upload modal */}

      <Wrapper>
        <Modal
          title="Add Single Deal"
          open={isOpen}
          centered
          width={800}
          bodyStyle={{
            padding: "0px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          onClose={() => {
            form.resetFields();
            setIsOpen(false);
          }}
          onCancel={() => {
            form.resetFields();
            setIsOpen(false);
          }}
          footer={null}
        >
          <div>
            <Form
              form={form}
              name="register"
              colon={false}
              onFinish={async (values) => {
                setButtonLoading(true);
                if (EditData) {
                  let response = await serviceCaller.UpdateDeals(
                    EditData?._id,
                    values
                  );
                  console.log("response from update : ", response);
                  if (response?.status === 200) {
                    notification.success({
                      message: response?.data?.message,
                    });
                    setEditData(undefined);
                    setRefresh(!refresh);
                    setIsOpen(false);
                    setButtonLoading(false);
                    form.resetFields();
                    window.location.reload();
                  } else {
                    setRefresh(!refresh);
                    setButtonLoading(false);
                    notification.error({
                      message: response?.data?.message,
                    });
                  }
                } else {
                  setRefresh(!refresh);
                  setButtonLoading(false);
                  console.log("val", values);
                  submitSingleDeal(values);
                }
              }}
              requiredMark={false}
              initialValues={
                {
                  // residence: ['zhejiang', 'hangzhou', 'xihu'],
                  // prefix: '86',
                }
              }
              scrollToFirstError
            >
              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Product Name" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter product name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Category" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="category_id"
                    rules={[
                      {
                        required: true,
                        message: "Please enter category",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a category"
                      optionFilterProp="children"
                    >
                      {Category?.map((item) => (
                        <Option value={item?._id}>{item?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Amazon Price" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="amazon_price"
                    rules={[
                      {
                        required: true,
                        message: "Please enter amazonPrice",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Store Price" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="store_price"
                    rules={[
                      {
                        required: true,
                        message: "Please enter storePrice",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Net Profit" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="net_profit"
                    rules={[
                      {
                        required: true,
                        message: "Please enter netProfit",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Price coin (in $)" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="deal_price"
                    rules={[
                      {
                        required: true,
                        message: "Please enter storePrice",
                      },
                    ]}
                  >
                    <InputNumber  />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Amazon Rank" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="ninety_days_rank"
                    rules={[
                      {
                        required: true,
                        message: "Please enter amazonRank",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Store Name" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="store_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter storeName",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Sellers Count" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="sellers_count"
                    rules={[
                      {
                        required: true,
                        message: "Please enter sellerscount",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Country Name" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="country_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter country name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="ROI" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="roi"
                    rules={[
                      {
                        required: true,
                        message: "Please enter ROI",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="ASIN" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="asin"
                    rules={[
                      {
                        required: true,
                        message: "Please enter ASIN",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="BSR" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="bsr"
                    rules={[
                      {
                        required: true,
                        message: "Please enter BSR",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="List Id" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="plan_list_id"
                    rules={[
                      {
                        required: true,
                        message: "Please enter List Id",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row> */}

              <Row gutter={24} align="middle">
                {/* <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="EST Montly Sale" />
                </Col> */}

                {/* <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="estimated_monthly_sales"
                    rules={[
                      {
                        required: true,
                        message: "Please enter EST Montly Sale",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col> */}

                {/* <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Deal Type" />
                </Col> */}

                {/* <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="plan_type"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Deal Type",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col> */}
              </Row>

              <Row gutter={24} align="middle">
                {/* <Col xs={12} sm={12} md={4} lg={64} xxl={4}>
                  <Form.Item label="Shipping Note" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="shipping_notes"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Shipping Note",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col> */}

                {/* <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Notes" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Notes",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col> */}
              </Row>

              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Purchased Deal" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="purchased_deal"
                    rules={[
                      {
                        required: true,
                        message: "Please enter purchaseddeal",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>           

                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Image" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="image"
                    rules={[
                      {
                        required: true,
                        message: "Please enter image",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            
              <Row gutter={24} align="middle">
                <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Brand" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="brand"
                    rules={[
                      {
                        required: true,
                        message: "Please enter brand",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>           

{/* <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
                  <Form.Item label="Image" />
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xxl={8}>
                  <Form.Item
                    name="image"
                    rules={[
                      {
                        required: true,
                        message: "Please enter image",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col> */}
              </Row>
            
              <Row gutter={24} align="middle">
                <Col xs={12} sm={8} md={4} lg={4} xxl={4}>
                  <Form.Item label="Amazon URL" />
                </Col>
                <Col xs={12} sm={16} md={20} lg={20} xxl={20}>
                  <Form.Item
                    name="amazon_product_url"
                    rules={[
                      { required: true }, 
                      { type: 'url', warningOnly: true }, 
                      { type: 'string', min: 6 }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            <Row gutter={24} align="middle">
                <Col xs={12} sm={8} md={4} lg={4} xxl={4}>
                  <Form.Item label="Store URL" />
                </Col>
                <Col xs={12} sm={16} md={20} lg={20} xxl={20}>
                  <Form.Item
                    name="store_product_url"
                    rules={[
                      { required: true }, 
                      { type: 'url', warningOnly: true }, 
                      { type: 'string', min: 6 }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} align="middle" style={{ padding: "0px" }}>
                <Col
                  xs={12}
                  sm={12}
                  md={20}
                  lg={20}
                  xxl={20}
                  style={{ padding: "0px" }}
                ></Col>
                <Col
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xxl={4}
                  style={{ padding: "0px" }}
                >
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={ButtonLoading}
                      className="login-form-button"
                    >
                      {EditData ? "Update Deal" : "Add Deal"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      </Wrapper>
    </Wrapper>
  );
};

export default DashboardIndex;
