import moment from "moment";
import {
  Table,
  Button,
  Modal,
  Form,
  Row,
  Pagination,
  Col,
  InputNumber,
  Input,
  Switch,
  Divider,
  notification,
  message,
} from 'antd';
import React, { useEffect, useMemo, useState } from "react";
import { FaAmazon } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { VscDebugRestart } from "react-icons/vsc";
import styled from "styled-components";
// import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as serviceCaller from "../../Services";
import MuiAlert from "@mui/material/Alert";
import { useSnackbar } from "notistack";
import {
  RightCircleOutlined,
  DownCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { CgAdd } from "react-icons/cg";


const Wrapper = styled.div`
  .ant-row-middle {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`;
const SwitchStyle = styled.div`
  .ant-switch-checked {
    background-color: #ff9900;
  }
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const DealsViewTable = (props) => {
  const [form] = Form.useForm();
  const [dataArray, setDataArray] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [SelectPlan, setSelectPlan] = useState();
  const [refresh, setRefresh] = useState(false);
  const [Opened, setOpened] = useState();
  const [pageSizeValue, setPageSizeValue] = React.useState();
  const [AddListModal, setAddListModal] = useState(false);
  const [addCoin, setAddCoin] = useState();

  useEffect(() => {
    if (window?.screen?.height > 900) {
      setPageSizeValue(15);
    } else {
      setPageSizeValue(10);
    }
  }, []);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setArrayDataforDeals();
    } else {
      setDataArray();
    }
  }, [props?.Tab, props?.refreshDeals, props?.data?.length, props]);

  const setArrayDataforDeals = async () => {
    const dataData = [];
    await props?.data?.map((item, index) => {
      item.key = index;
      dataData?.push(item);
    });
    setDataArray(dataData);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  console.log("userview_props", props);

  const columns = [
    // {
    //   title:"Actions",
    //   width: 100,
    //   key: 'action',
    //   fixed:"left",
    //   render: (value) => {
    //     return(
    //       <div style={{display:"flex",alignItems:"center"}}>
    //         <Button type='link' target='_blank' href={`${value?.amazon_product_url}`}>
    //           <FaAmazon size={"120%"}/>
    //         </Button>
    //         <Button
    //           type='link'
    //           style={{marginTop:"-8px"}}
    //           onClick={async()=>{
    //             if(props?.deals==="deleted"){
    //               const Restore_deal = await serviceCaller.restoreDeals(value?._id,value);
    //               handleClick();
    //               if(Restore_deal?.data?.restored){
    //                 // window.location.reload();
    //                 props.Refresh();
    //               }
    //             }else{
    //               const Delete_deal = await serviceCaller.deleteDeals(value?._id,value);
    //               handleClick();
    //               if(Delete_deal?.data?.deleted){
    //                 // window.location.reload();
    //                 props.Refresh();
    //               }
    //             }
    //           }}
    //         >
    //           {props?.deals==="deleted" ? (
    //             <VscDebugRestart size={"140%"}/>
    //           ):(
    //             <MdDelete size={"140%"}/>
    //           )}
    //         </Button>
    //       </div>
    //     )
    //   },
    // },
    {
      title: "Name",
      key: "image",
      width: 130,
      fixed: "left",
      render: (value) => {
        return (
          <div>
            <span>{value?.first_name}</span>
          </div>
        );
      },
    },
    {
      title: "Email",
      key: "image",
      width: 130,
      fixed: "left",
      render: (value) => {
        return (
          <div>
            <span>{value?.email}</span>
          </div>
        );
      },
    },
    {
      title: "KeyCloak Id",
      key: "image",
      width: 130,
      fixed: "left",
      render: (value) => {
        return (
          <div>
            <span>{value?.kc_id}</span>
          </div>
        );
      },
    },
    {
      title: "Stripe Customer_id Id",
      key: "image",
      width: 130,
      fixed: "left",
      render: (value) => {
        return (
          <div>
            <span>{value?.stripe_customer_id}</span>
          </div>
        );
      },
    },
  ];

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // console.log("dataArraydataArray",dataArray)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.resetFields();
    setAddCoin(undefined);
  };
  const handleCancel = () => {
    setAddCoin(undefined);
  };


  const openNotification = () => {
    notification.success({
      message: 'User coins added',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });

  };

  // console.log("dataArray",dataArray);

  return (
    <Wrapper>
      <div>
        {dataArray?.map((value) => (
          <>
            <Row align="middle" justify="start">
              <Col
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xxl={1}
                style={{ padding: "0px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "18px",
                  }}
                >
                  {Opened?._id === value?._id ? (
                    <DownCircleOutlined
                      onClick={(e) => {
                        setOpened(undefined);
                      }}
                    />
                  ) : (
                    <RightCircleOutlined
                      onClick={(e) => {
                        setOpened(undefined);
                        setOpened(value);
                      }}
                    />
                  )}
                </div>
              </Col>
              <Col xs={22} sm={10} md={5} lg={5} xxl={5}>
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {"Name"}
                  </div>
                  &nbsp;&nbsp;
                  <div style={{ fontSize: "12px" }}>{value?.first_name}</div>
                </div>
              </Col>
              <Col xs={12} sm={10} md={6} lg={6} xxl={6}>
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {"Email"}
                  </div>{" "}
                  &nbsp;&nbsp;
                  <div style={{ fontSize: "12px" }}>{value?.email}</div>
                </div>
              </Col>
              <Col xs={12} sm={10} md={6} lg={6} xxl={6}>
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {"KeyCloak Id"}
                  </div>{" "}
                  &nbsp;&nbsp;
                  <div style={{ fontSize: "12px" }}>{value?.kc_id}</div>
                </div>
              </Col>
              <Col xs={12} sm={10} md={6} lg={6} xxl={6}>
                {/* <div style={{textAlign:"left"}}>
                  <div style={{fontWeight:"bold",fontSize:"12px"}}>{"Stripe Id"}</div> &nbsp;&nbsp;
                  <div style={{fontSize:"12px"}}>{value?.stripe_customer_id}</div>
                </div> */}
                <div>
                  <Button
                    type="primary"
                    onClick={() => {
                      setAddCoin(value);
                    }}
                  >
                    Add Coin
                  </Button>
                </div>
              </Col>
            </Row>
            <div>
              {/* {(Opened?._id===value?._id) && (
                  <div style={{marginLeft:"50px"}}>
                    {Opened?.Subscription_lists.map((item,index)=>(
                      <>
                        <Row align='middle'>
                          <Col xs={12} sm={12} md={1} lg={1} xxl={1}>
                            <div style={{textTransform:"capitalize",fontWeight:"bold",fontSize:"16px"}}>
                              {index+1}
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={5} lg={5} xxl={5}>
                            <div style={{textTransform:"capitalize",fontWeight:"bold",fontSize:"16px"}}>{item?.tranInitPlanFromGateway?.nickname}</div>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                            <div style={{textTransform:"capitalize",fontWeight:"bold",fontSize:"16px"}}>{item?.tranInitStatus}</div>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                            <div style={{textTransform:"capitalize",fontWeight:"bold",fontSize:"16px"}}>
                              Start : {moment(new Date(item?.tranInitSubscriptionFromGateway?.current_period_end*1000)).format("DD-MMM-YYYY")}
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
                            <div style={{textTransform:"capitalize",fontWeight:"bold",fontSize:"16px"}}>
                              End : {moment(new Date(item?.tranInitSubscriptionFromGateway?.current_period_start*1000)).format("DD-MMM-YYYY")}
                            </div>
                          </Col>
                        </Row>
                      </>
                    ))}
                  </div>
                )} */}
            </div>
          </>
        ))}
        {props?.data?.length >= 10 && (
          <Pagination
            size="default"
            defaultCurrent={1}
            defaultPageSize={pageSizeValue}
            total={props?.AllData?.totalDocs}
            responsive={true}
            onChange={(value, pageSize) => {
              props?.PaginationUpdate(value, pageSize);
            }}
          />
        )}
      </div>

      <Modal
        // title="Basic Modal"
        open={addCoin}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="AddCoin"
            name="AddCoin"
            rules={[{ required: true, message: "Please input your AddCoin!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={async (value) => {
                let formvalue = form.getFieldsValue()
                console.log({ formvalue });
                console.log({ addCoin });
                const UserAddCoin = await serviceCaller.AddCoinToUser(
                  addCoin,
                  formvalue
                );
                // console.log("UserAddCoin-->",UserAddCoin);
                form.resetFields();
                setAddCoin(undefined);
                openNotification('success');;
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Wrapper>
  );
};
export default DealsViewTable;
