import React, { useContext, useEffect, useState } from "react";
import DashboardIndex from './Dashboard';
import {
  Grid,
  Typography,
} from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";

import { createContext } from "react";


export const context = createContext();

function App() {
  const [keycloak, initialized] = useKeycloak();
  useEffect(()=>{
    if (initialized && keycloak.authenticated) {
      let UserDetails=setUser();
    }
  },[]);
  const setUser=async()=>{
    const value={
      keycloak:keycloak?.idTokenParsed,
      keycloak_Token: keycloak?.idToken,
    }
    localStorage.setItem("keycloak_Token", keycloak?.idToken);
    localStorage.setItem("keycloak_id", keycloak?.idTokenParsed?.sub);
    localStorage.setItem("keycloak_email", keycloak?.idTokenParsed?.email);
    return value;
  }
  if (initialized && keycloak.authenticated) {
    if (!keycloak.realmAccess.roles.some((e) => e == "app-admin")) {
      return (
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Typography>Your are not authorised</Typography>
          </Grid>
        </Grid>
      );
    }


    return (
      <>
        <div>
          <DashboardIndex/>
        </div>
      </>
    );
  } else {
    keycloak.login();
  }
}

export default App;
